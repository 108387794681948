import { Card, Form, Row, Col } from "react-bootstrap";
import { useFormContext } from "react-hook-form";

const AssessmentQuestion = ({ path, factor, ...rest }) => {
  const { register } = useFormContext();

  return (
    <div className="mx-3" {...rest}>
      <Card.Text className="mb-1">{`${factor}:`}</Card.Text>
      <Form.Group as={Row} className="mb-3">
        <Col xs="12" sm="6" md="3">
          <Form.Check type="radio" label="Low" value="low" id={`${path}.Low`} {...register(path)} />
        </Col>
        <Col xs="12" sm="6" md="3">
          <Form.Check type="radio" label="Medium" value="medium" id={`${path}.Medium`} {...register(path)} />
        </Col>
        <Col xs="12" sm="6" md="3">
          <Form.Check type="radio" label="High" value="high" id={`${path}.High`} {...register(path)} />
        </Col>
        <Col xs="12" sm="6" md="3">
          <Form.Check type="radio" label="Not Applicable" value="na" id={`${path}.NA`} {...register(path)} />
        </Col>
      </Form.Group>
    </div>
  );
};

export default AssessmentQuestion;
