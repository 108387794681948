export const data = [
  {
    title: "Organization",
    subsections: [
      {
        title: "Characteristics",
        factors: [
          {
            title: "Organization Size",
          },
          {
            title: "Innovation Atmosphere",
          },
          {
            title: "Previous Experience",
          },
        ],
      },
      {
        title: "Management",
        factors: [
          {
            title: "Strategic Planning",
          },
          {
            title: "Top Management Support",
          },
          {
            title: "Buyer-Supplier Relationship",
          },
        ],
      },
    ],
  },
  {
    title: "Business",
    subsections: [
      {
        title: "Marketing",
        factors: [
          {
            title: "Competitive Advantage",
          },
          {
            title: "Brand Image",
          },
        ],
      },
      {
        title: "Financial",
        factors: [
          {
            title: "Cost-Benefit Analysis",
          },
          {
            title: "Demand Analysis",
          },
        ],
      },
    ],
  },
  {
    title: "Human",
    subsections: [
      {
        title: "Mindset",
        factors: [
          {
            title: "Perceived Usefulness",
          },
          {
            title: "Perceived Risk",
          },
          {
            title: "Perceived Ease of Use",
          },
          {
            title: "Attitude Toward Sharing",
          },
          {
            title: "Attitude Toward Change",
          },
        ],
      },
      {
        title: "Competency",
        factors: [
          {
            title: "Digital Skills",
          },
          {
            title: "Training",
          },
        ],
      },
    ],
  },
  {
    title: "Technology",
    subsections: [
      {
        title: "Maintainability",
        factors: [
          {
            title: "Compatibility",
          },
          {
            title: "IT Support",
          },
          {
            title: "Security and Data Protection",
          },
        ],
      },
      {
        title: "Establishment",
        factors: [
          {
            title: "Complexity and Architecture",
          },
          {
            title: "Resource Readiness",
          },
          {
            title: "Cloud Readiness",
          },
        ],
      },
    ],
  },
  {
    title: "Environment",
    subsections: [
      {
        title: "Industry",
        factors: [
          {
            title: "Market Pressure",
          },
          {
            title: "Market Uncertainty",
          },
          {
            title: "Consumer Readiness",
          },
        ],
      },
      {
        title: "Legislation",
        factors: [
          {
            title: "Government Regulations",
          },
          {
            title: "Clear Cloud Usage Policy",
          },
        ],
      },
    ],
  },
];
