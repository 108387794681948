import { Row, Col } from "react-bootstrap";
import ActorButton from "../components/ActorButton";
import bg1 from "../assets/bg1.png";
import bg2 from "../assets/bg2.png";
import bg3 from "../assets/bg3.png";

const ActorSelection = ({ onSelectActor }) => {
  return (
    <div>
      <div className="mb-5 text-center">
        <h1 className="m-0" style={{ color: "rgb(44, 123, 229)", fontFamily: "sans-serif", fontWeight: 800 }}>
          CMAA
        </h1>
        <div style={{ fontWeight: 200, color: "rgb(91, 110, 130)" }}>Cloud Manufacturing Adoption Assessment</div>
      </div>
      <div className="d-none d-md-block">
        <Row className="justify-content-center">
          <Col xs="3" xl="2" className="">
            <ActorButton title="Platform Provider" onSelectActor={onSelectActor} image={bg1} />
          </Col>
          <Col xs="3" xl="2">
            <ActorButton title="Resource Provider" onSelectActor={onSelectActor} image={bg2} />
          </Col>
          <Col xs="3" xl="2">
            <ActorButton title="User" onSelectActor={onSelectActor} image={bg3} />
          </Col>
        </Row>
      </div>
      <div className="d-block d-md-none">
        <Row className="justify-content-center mb-3">
          <Col xs="8" sm="6" className="">
            <ActorButton title="Platform Provider" onSelectActor={onSelectActor} image={bg1} />
          </Col>
        </Row>
        <Row className="justify-content-center mb-3">
          <Col xs="8" sm="6">
            <ActorButton title="Resource Provider" onSelectActor={onSelectActor} image={bg2} />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs="8" sm="6">
            <ActorButton title="User" onSelectActor={onSelectActor} image={bg3} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ActorSelection;
