import { Fragment } from "react";
import { Card } from "react-bootstrap";
import CustomCard from "../components/CustomCard.js";
import AssessmentQuestion from "../components/AssessmentQuestion.js";
import { data } from "../data.js";

const Assessment = ({ actor, onBack, onNext }) => {
  return (
    <>
      <CustomCard header={`${actor} Role`} onBack={onBack} onNext={onNext} />
      {data.map((category, categoryIndex) => (
        <CustomCard key={categoryIndex} title={category.title}>
          {category.subsections?.map((subsection, subsectionIndex, subsections) => (
            <Fragment key={subsectionIndex}>
              <Card.Title className="mb-3">{subsection.title}</Card.Title>
              {subsection.factors?.map((factor, factorIndex) => (
                <AssessmentQuestion
                  key={factorIndex}
                  path={`assessment.${categoryIndex}.subsections.${subsectionIndex}.factors.${factorIndex}.value`}
                  factor={factor.title}
                />
              ))}
              {subsectionIndex < subsections.length - 1 && <hr />}
            </Fragment>
          ))}
        </CustomCard>
      ))}
    </>
  );
};

export default Assessment;
