import { useFormContext } from "react-hook-form";

const MainLayout = ({ children }) => {
  const { setValue } = useFormContext();

  const setDefaultValues = () => {
    setValue("assessment.0.subsections.0.factors.0.value", "low");
    setValue("assessment.0.subsections.0.factors.1.value", "medium");
    setValue("assessment.0.subsections.0.factors.2.value", "high");
    setValue("assessment.0.subsections.1.factors.0.value", "low");
    setValue("assessment.0.subsections.1.factors.1.value", "medium");
    setValue("assessment.0.subsections.1.factors.2.value", "high");
    setValue("assessment.1.subsections.0.factors.0.value", "low");
    setValue("assessment.1.subsections.0.factors.1.value", "medium");
    setValue("assessment.1.subsections.1.factors.0.value", "high");
    setValue("assessment.1.subsections.1.factors.1.value", "low");
    setValue("assessment.2.subsections.0.factors.0.value", "medium");
    setValue("assessment.2.subsections.0.factors.1.value", "high");
    setValue("assessment.2.subsections.0.factors.2.value", "low");
    setValue("assessment.2.subsections.0.factors.3.value", "medium");
    setValue("assessment.2.subsections.0.factors.4.value", "high");
    setValue("assessment.2.subsections.1.factors.0.value", "low");
    setValue("assessment.2.subsections.1.factors.1.value", "medium");
    setValue("assessment.3.subsections.0.factors.0.value", "high");
    setValue("assessment.3.subsections.0.factors.1.value", "low");
    setValue("assessment.3.subsections.0.factors.2.value", "medium");
    setValue("assessment.3.subsections.1.factors.0.value", "high");
    setValue("assessment.3.subsections.1.factors.1.value", "low");
    setValue("assessment.3.subsections.1.factors.2.value", "medium");
    setValue("assessment.4.subsections.0.factors.0.value", "high");
    setValue("assessment.4.subsections.0.factors.1.value", "low");
    setValue("assessment.4.subsections.0.factors.2.value", "medium");
    setValue("assessment.4.subsections.1.factors.0.value", "high");
    setValue("assessment.4.subsections.1.factors.1.value", "low");
  };

  return (
    <>
      <div className="mb-3 ms-4">
        <h1 className="m-0" style={{ color: "rgb(44, 123, 229)", fontFamily: "sans-serif", fontWeight: 800 }}>
          CMAA
        </h1>
        <div onClick={() => setDefaultValues()} style={{ fontWeight: 200, color: "rgb(91, 110, 130)" }}>
          Cloud Manufacturing Adoption Assessment
        </div>
      </div>
      {children}
    </>
  );
};

export default MainLayout;
